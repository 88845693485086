import type { Service } from '~/server/api/diagnostics/log'

interface Payload {
  message: string
  code?: string
  data?: string
}

export function useServerLogging(serviceName: Service) {
  async function logError(payload: Payload) {
    return await $fetch('/api/diagnostics/log', {
      method: 'POST',
      body: {
        ...payload,
        code: `${serviceName}_${payload.code}`,
        level: 'error',
        service: serviceName,
      },
    })
  }

  async function logInfo(payload: Payload) {
    return await $fetch('/api/diagnostics/log', {
      method: 'POST',
      body: {
        ...payload,
        code: `${serviceName}_${payload.code}`,
        level: 'info',
        service: serviceName,
      },
    })
  }

  async function logWarning(payload: Payload) {
    return await $fetch('/api/diagnostics/log', {
      method: 'POST',
      body: {
        ...payload,
        code: `${serviceName}_${payload.code}`,
        level: 'warning',
        service: serviceName,
      },
    })
  }

  return {
    logError,
    logInfo,
    logWarning,
  }
}
